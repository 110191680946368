<template>
  <div
      class="opus_box"
      :style="{height:cardData.platformCaseType === 9 ? cardData.realHeight + 110 + 'px' : cardData.realHeight + 83 + 'px'}"
  >
    <div class="img-enlarge-box">
      <img-enlarge :src="cardData.coverFilePath" :style="{ height: cardData.realHeight+ 'px' }" />
      <img :src="playIcon" alt="" class="play-icon" v-if="cardData.platformCaseType=== 5">
      <div class="duration" v-if="cardData.platformCaseType=== 5">
        {{cardData.duration}}
      </div>
    </div>
    <div class="content"   :style="cardData.platformCaseType === 9?{ height:  110 + 'px' } :{height:  83 + 'px'}">
      <p class="card-title hidden_row2">{{ cardData.title ? cardData.title : '暂无标题' }}</p>
      <div class="house-type" v-if="cardData.platformCaseType === 9">
        <span >
          {{ cardData.houseType }}丨{{cardData.houseArea}}丨{{cardData.region}}
        </span>
      </div>
      <div class="footer-msg">
        <div class="left">
          <user-avatar v-if="cardData.id" :size="24" :showIdentification="true" @click.stop
            :identificationSize="props.userIdentificationSize" :src="cardData.avatar"
            :user-type="getUserType(cardData.identityInfoType)"
            :userId="cardData.creatorId"
             />
          <nuxt-link class="user-name" :to="'/personalHomePage?=' + cardData.creatorId" target="_blank">{{ cardData.nickName ? cardData.nickName : '暂无昵称' }}</nuxt-link>
        </div>
        <div class="right">
          <img
              v-if="!props.cardData.isFavorite"
              src="../../../assets/icons/home_clecte_unsel.svg"
              @click.stop="favorite"
          >
          <img
              v-else src="../../../assets/icons/home_clecte_sel.svg"
              @click.stop="favorite"
          >
          <div class="collect-num">{{ props.cardData.quantity }}</div>
        </div>
      </div>
    </div>
    <!-- 收藏夹 -->
    <ClientOnly>
      <Transition>
        <Facorites v-if="data.favoriteShow" :id="props.cardData.id" :open="favorite" :close="close">
        </Facorites>
      </Transition>
    </ClientOnly>
  </div>
</template>

<script setup>
import playIcon from '@/assets/icons/PC_home_play_icon.svg'
import { FavoriteApi } from '@/apis/api'
const favoriteApi = new FavoriteApi()
const router = useRouter()
import { useMessage } from 'naive-ui'
const message = useMessage()
const data = reactive({
  size: {},
  favoriteShow:false
})
const props = defineProps({
  // 卡片宽度
  width: {
    type: Number,
    default: 344
  },
  // 用户身份标识大小
  userIdentificationSize: {
    type: Number
  },
  // 卡片数据
  cardData: {
    type: Object,
    default: {}
  },
})
const { cardData } = { ...props }
// console.log(cardData,232222222222);
// console.log(cardData);
// 收藏和取消收藏
// 收藏并选择文件夹
async function favorite() {
  //收藏
  if (!props.cardData.isFavorite) {
    console.log('点击收藏')
    let res = await favoriteApi.apiAppFavoriteFavoritePost({
      id: props.cardData.id,
      projectSourceType: 2
    })
    if (res.status === 200 || res.status === 204) {
      data.favoriteShow = true
      props.cardData.quantity = res.data
      props.cardData.isFavorite = true
    }
  }
  //取消收藏
  else {
    let res = await favoriteApi.apiAppFavoriteUnFavoritePost({
      id: props.cardData.id,
      projectSourceType: 2
    })
    if (res.status === 200 || res.status === 204) {
      message.info('取消收藏')
      props.cardData.quantity = res.data
      props.cardData.isFavorite = false
    }
  }
}
const close = () => {
  data.favoriteShow = false
}
</script>

<style lang="less" scoped>
.opus_box {
  width:363px;
 // min-width: 344px;
  cursor: pointer;
  // flex: 1;
  .img-enlarge-box {
    position: relative;
    .play-icon {
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
    }
    .duration{
      font-size: 11px;
      font-weight: 500;
      color: #ffffff;
      position: absolute;
      bottom: 9px;
      right: 10px;
    }
  }
  .content {
    padding: 8px 12px 12px 12px;
    background: #fff;
    font-family: PingFangSC, PingFangSC-Medium;
    border-radius: 0 0 9px 9px;

    .card-title {
      line-height: 21px;
      @apply text-f15 font-medium;
      &:hover{
        color: #f39e00;
      }
    }

    .house-type {
      height: 17px;
      margin-top: 10px;
      @apply text-f12 text-oc1-1 font-medium flex justify-start items-center;
    }

    .footer-msg {
      //padding-top: 20px;
      //padding-bottom: 12px;
      height: 24px !important;
      //border: 1px solid red;
     margin-top: 20px;
      margin-bottom: 12px;
      @apply flex justify-between items-center;

      .left {
        @apply flex justify-start items-center;

        .user-name {
          margin-left: 6px;
          @apply font-normal text-fc1 text-f12;
          &:hover{
            color: #f39e00;
          }
        }
      }

      .right {
        @apply flex justify-start items-center;
        img{
        @apply cursor-pointer;
        }
        .collect-num {
          margin-left: 7px;
          color: #656666;
          @apply font-normal text-f12;
        }
      }
    }
  }
}
</style>